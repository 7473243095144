import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  
  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}

// import { useState } from 'react';
// import { Outlet } from 'react-router-dom';
// // material
// import { experimentalStyled as styled } from '@material-ui/core/styles';
// //
// import DashboardNavbar from './DashboardNavbar';
// import DashboardSidebar from './DashboardSidebar';

// // ----------------------------------------------------------------------

// const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 92;
// const DRAWER_WIDTH = 280;

// const RootStyle = styled('div')({
//   display: 'flex',
//   minHeight: '100%',
//   overflow: 'hidden'
// });

// const MainStyle = styled('main')(({ theme, open }) => ({
//   flexGrow: 1,
//   overflow: 'auto',
//   minHeight: '100%',
//   paddingTop: APP_BAR_MOBILE + 24,
//   paddingBottom: theme.spacing(10),
//   transition: theme.transitions.create('margin', {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   marginLeft: open ? DRAWER_WIDTH : 80,
//   [theme.breakpoints.up('lg')]: {
//     paddingTop: APP_BAR_DESKTOP + 24,
//     paddingLeft: theme.spacing(2),
//     paddingRight: theme.spacing(2)
//   }
// }));

// // ----------------------------------------------------------------------

// export default function DashboardLayout() {
//   const [open, setOpen] = useState(true); // Default to true to show the sidebar initially
  
//   return (
//     <RootStyle>
//       <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
//       <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
//       <MainStyle open={open}>
//         <Outlet />
//       </MainStyle>
//     </RootStyle>
//   );
// }
