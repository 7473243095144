import { useEffect } from 'react';
import { paramCase } from 'change-case';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
// material
import { Container, Divider, Stack } from '@material-ui/core';
// redux
import { useDispatch, useSelector } from '../../../../../redux/store';
import { getUserList } from '../../../../../redux/slices/user';
// components
import Page from '../../../../../components/Page';
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
import AddBrand from './AddBrand';
import { MIconButton } from 'src/components/@material-extend';
import { ArrowBack } from '@material-ui/icons';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

export default function AddeditBrand({setEdit}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { name } = useParams();
  const { userList } = useSelector((state) => state.user);
  const isEdit = pathname.includes('edit');
  const currentUser = userList.find((user) => paramCase(user.name) === name);

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  return (
    <Page title="Batman Distribution">
      <Container>
        <Stack direction="row" alignItems="center" gap={1}>
          <MIconButton sx={{ mb:5.6, backgroundColor: { md: 'rgba(110, 97, 84, 0.16)', xs: 'transparent',height:'40px',width:'40px' },
            color: '#000',
            '&:hover': {
              backgroundColor: { md: 'rgba(0, 0, 0, 0.3)', xs: 'transparent' },
            },}} onClick={() => navigate(PATH_DASHBOARD.inventory.Brand)}>
            <ArrowBack sx={{fontSize:'20px'}}/>
          </MIconButton>
          <HeaderBreadcrumbs
            heading={isEdit ? 'Add Brand' : 'Edit Brand '}
          />
        </Stack>
        <AddBrand isEdit={isEdit} currentUser={currentUser} />
      </Container>
    </Page>
  );
}
