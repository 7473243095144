// import { alpha } from '@material-ui/core/styles';

// // ----------------------------------------------------------------------

// function createGradient(color1, color2) {
//   return `linear-gradient(to bottom, ${color1}, ${color2})`;
// }

// // SETUP COLORS
// const GREY = {
//   0: '#FFFFFF',
//   100: '#F9FAFB',
//   200: '#F4F6F8',
//   300: '#DFE3E8',
//   400: '#C4CDD5',
//   500: '#919EAB',
//   600: '#637381',
//   700: '#454F5B',
//   800: '#212B36',
//   900: '#161C24',
//   500_8: alpha('#919EAB', 0.08),
//   500_12: alpha('#919EAB', 0.12),
//   500_16: alpha('#919EAB', 0.16),
//   500_24: alpha('#919EAB', 0.24),
//   500_32: alpha('#919EAB', 0.32),
//   500_48: alpha('#919EAB', 0.48),
//   500_56: alpha('#919EAB', 0.56),
//   500_80: alpha('#919EAB', 0.8)
// };

// const PRIMARY = {
//   lighter: '#C8FACD',
//   light: '#5BE584',
//   main: '#00AB55',
//   dark: '#007B55',
//   darker: '#005249',
//   contrastText: '#fff'
// };
// const SECONDARY = {
//   lighter: '#D6E4FF',
//   light: '#84A9FF',
//   main: '#3366FF',
//   dark: '#1939B7',
//   darker: '#091A7A',
//   contrastText: '#fff'
// };
// const INFO = {
//   lighter: '#D0F2FF',
//   light: '#74CAFF',
//   main: '#1890FF',
//   dark: '#0C53B7',
//   darker: '#04297A',
//   contrastText: '#fff'
// };
// const SUCCESS = {
//   lighter: '#E9FCD4',
//   light: '#AAF27F',
//   main: '#54D62C',
//   dark: '#229A16',
//   darker: '#08660D',
//   contrastText: GREY[800]
// };
// const WARNING = {
//   lighter: '#FFF7CD',
//   light: '#FFE16A',
//   main: '#FFC107',
//   dark: '#B78103',
//   darker: '#7A4F01',
//   contrastText: GREY[800]
// };
// const ERROR = {
//   lighter: '#FFE7D9',
//   light: '#FFA48D',
//   main: '#FF4842',
//   dark: '#B72136',
//   darker: '#7A0C2E',
//   contrastText: '#fff'
// };

// const GRADIENTS = {
//   primary: createGradient(PRIMARY.light, PRIMARY.main),
//   info: createGradient(INFO.light, INFO.main),
//   success: createGradient(SUCCESS.light, SUCCESS.main),
//   warning: createGradient(WARNING.light, WARNING.main),
//   error: createGradient(ERROR.light, ERROR.main)
// };

// const COMMON = {
//   common: { black: '#000', white: '#fff' },
//   primary: { ...PRIMARY },
//   secondary: { ...SECONDARY },
//   info: { ...INFO },
//   success: { ...SUCCESS },
//   warning: { ...WARNING },
//   error: { ...ERROR },
//   grey: GREY,
//   gradients: GRADIENTS,
//   divider: GREY[500_24],
//   action: {
//     hover: GREY[500_8],
//     selected: GREY[500_16],
//     disabled: GREY[500_80],
//     disabledBackground: GREY[500_24],
//     focus: GREY[500_24],
//     hoverOpacity: 0.08,
//     disabledOpacity: 0.48
//   }
// };

// const palette = {
//   light: {
//     ...COMMON,
//     text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
//     background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
//     action: { active: GREY[600], ...COMMON.action }
//   },
//   dark: {
//     ...COMMON,
//     text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
//     background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
//     action: { active: GREY[500], ...COMMON.action }
//   }
// };

// export default palette;
import { alpha } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

// const PRIMARY = {
//   lighter: '#C8FACD',
//   light: '#5BE584',
//   main: '#00AB55',
//   dark: '#007B55',
//   darker: '#005249',
//   contrastText: '#fff'
// };

// const PRIMARY = {
//   lighter: '#FFB3C1',  // Light pinkish-red (lighter shade of #000)
//   light: '#EC667F',    // Light pinkish-red (lighter version of #000)
//   main: '#000',     // The new primary color
//   dark: '#990A2D',     // Darker version of #000
//   darker: '#66071F',   // Even darker version of #000
//   contrastText: '#000' // Black text for contrast
// };
// ------------ seema red above 

// const PRIMARY = {
//   lighter: '#FFED80',    // Lighter version of #000, a soft yellow
//   light: '#FFE34D',      // Light yellow, a slightly lighter version of #000
//   main: '#000',       // The main color, a golden yellow
//   dark: '#C7A803',       // Darker version of #000, a mustard yellow
//   darker: '#8F7602',     // Even darker version, a deep gold
//   contrastText: '#000'   // Black text for contrast
// };
// ------------- yellow theme
const PRIMARY = {
  lighter: '#B3B3B3',    // Light grey for a softer tone
  light: '#808080',      // Standard grey, representing a lighter tone in the theme
  main: '#000000',       // Main color is black, as the primary Batman color
  dark: '#404040',       // Dark grey, slightly lighter than black
  darker: '#1A1A1A',     // Almost black, a deep dark grey
  contrastText: '#FFFFFF' // White text for contrast against the dark background
};

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff'
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff'
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800]
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800]
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff'
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

const palette = {
  // light: {
  //   ...COMMON,
  //   text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
  //   background: { paper: '#000000', default: '#000000', neutral: GREY[500_16] },
  //   action: { active: GREY[500], ...COMMON.action }
  // },
  light: {
    ...COMMON,
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action }
  },
  dark: {
    ...COMMON,
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    background: { paper: '#000000', default: '#000000', neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action }
  }
  // dark: {
  //   ...COMMON,
  //   text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
  //   background: { paper: '#000000', default: '#000000', neutral: GREY[500_16] },
  //   action: { active: GREY[500], ...COMMON.action }
  // }
};

export default palette;
