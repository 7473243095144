import PropTypes from 'prop-types';
// material
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import image from '../logo/image.png'
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ mr:2, width: 80, height: 80, ...sx }}>
      {/* <img src={image} /> */}
      <img src={image} height='80' width='80'/>

                      {/* <svg viewBox="0 0 288.15 112.94" xmlns="http://www.w3.org/2000/svg" width="80" height="64"><path d="M281.28 87.61c-4.1 0-7-3-7-7.28s2.87-7.28 7-7.28 6.87 3 6.87 7.28-2.77 7.28-6.87 7.28zM154.64 24.56l-.94 8.59c-3.24-6.47-10.31-9.82-18.71-9.82-16.07 0-28.81 12.73-28.81 32.14s12.36 32.14 28.19 32.14c9.77 0 16-4.58 19.16-10.11l1.11 8.88H166V24.56zM136 76.12c-10.5 0-18-8.64-18-20.9s7.34-20.9 18-20.9 17.58 8.51 17.58 20.9-7.13 20.9-17.58 20.9zM212.15 0v72.54s0 13.89 15.53 13.89h6.6v-11h-5.65c-2.59 0-4.14-2-4.14-7V0z" class="logo_svg__cls-1" fill="#000"></path><path d="m288.15 24.56-23.27 68.8c-3.58 11-9.75 19.58-22.89 19.58a21.31 21.31 0 0 1-14.85-6l5.2-9a14.81 14.81 0 0 0 9.16 3.59c5.19 0 9.15-4.46 11-9.9l1.73-5.2h-3.9l-21.65-61.87h13.73l16.1 49.65h.19l16.21-49.65zM44 75.43v11H0V0h12.36v75.43zm58-16.84H55.63c1 11 6.93 18.31 18.68 18.31 5.82 0 11.88-3 14.48-9H101c-3.09 13-15.19 19.79-26.84 19.79-19 0-30.81-12.86-30.81-32.41 0-18.81 12.87-31.92 29.82-31.92 15.42 0 28.75 9.49 28.83 31.28v3.95zm-12-9.53c-.22-8.16-6.28-15.46-16.43-15.46-9.16 0-15.59 6.06-17.44 15.46zM198.67 11H208V0h-13.5c-9.07 0-15 6.89-15 15.49v9.07h-9.37V35h9.37v51.43h12.4L192 35h15.4V24.56H192v-6.69c-.1-5.25 2.36-6.87 6.67-6.87z" class="logo_svg__cls-1" fill="#000"></path></svg> */}
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
          />
          <path
            fill="url(#BG2)"
            d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
          />
          <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          />
        </g>
      </svg> */}
    </Box>
  );
}
