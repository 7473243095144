import NProgress from 'nprogress';
import { motion } from 'framer-motion';
import { useEffect, useMemo } from 'react';
// material
import { alpha, makeStyles, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
//
import LogoNew from '../logo/loader-logo.png'

// ----------------------------------------------------------------------

const nprogressStyle = makeStyles((theme) => ({
  '@global': {
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        top: 0,
        left: 0,
        height: 2,
        width: '100%',
        position: 'fixed',
        zIndex: theme.zIndex.snackbar,
        backgroundColor: theme.palette.grey[900], // Changed to black
        boxShadow: `0 0 2px ${theme.palette.grey[900]}` // Changed to black
      },
      '& .peg': {
        right: 0,
        opacity: 1,
        width: 100,
        height: '100%',
        display: 'block',
        position: 'absolute',
        transform: 'rotate(3deg) translate(0px, -4px)',
        boxShadow: `0 0 10px ${theme.palette.grey[900]}, 0 0 5px ${theme.palette.grey[900]}` // Changed to black
      }
    }
  }
}));

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function LoadingScreen({ ...other }) {
  nprogressStyle();

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return (
    <RootStyle {...other}>
      <motion.div
        initial={{ rotateY: 0 }}
        animate={{ rotateY: 360 }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeat: Infinity
        }}
      >
        <img src={LogoNew} alt='Logo' style={{ width: 94, height: 94, borderRadius: '50%' }} />
      </motion.div>

      <Box
        component={motion.div}
        animate={{
          rotate: [0, 360],  // Clockwise rotation
          scale: [1, 1.1, 1], // Scale up and down
        }}
        transition={{
          ease: 'easeInOut',
          duration: 1.6,
          repeat: Infinity
        }}
        sx={{
          width: 100,
          height: 100,
          borderRadius: '50%',
          position: 'absolute',
          border: `solid 3px ${alpha('#FFE34D', 0.24)}` // Changed to #e6e6e6
        }}
      />

      <Box
        component={motion.div}
        animate={{
          rotate: [360, 0], // Counterclockwise rotation
          scale: [1, 1.1, 1], // Scale up and down
        }}
        transition={{
          ease: 'easeInOut',
          duration: 1.6,
          repeat: Infinity
        }}
        sx={{
          width: 120,
          height: 120,
          borderRadius: '50%',
          position: 'absolute',
          border: `solid 8px ${alpha('#000', 0.24)}` // Changed to white
        }}
      />
    </RootStyle>
  );
}
