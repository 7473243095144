// ----------------------------------------------------------------------

import AddVentor from "src/pages/dashboard/dashbordbeta/WholesaleManagment/Add/AddVentor";


                                     
function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DOCS = '/docs';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  products: '/shop',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    coupon: path(ROOTS_DASHBOARD, '/user/coupon'),
    CustomerPoint: path(ROOTS_DASHBOARD, '/user/customerpoint'),
    allcustomers: path(ROOTS_DASHBOARD, '/user/allcustomers'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, '/user/ada-lindgren/edit'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  vendor:{
    root: path(ROOTS_DASHBOARD, '/vendor'),
    AllVentors :path(ROOTS_DASHBOARD,'/vendor/allventors'),
    AllvenTypes :path(ROOTS_DASHBOARD,'/vendor/allventypes'),
    ReceiverInven :path(ROOTS_DASHBOARD,'/vendor/receiverInven'),
    // AddReceiverInven :path(ROOTS_DASHBOARD,'/vendor/add-receiverInven'),
    AllBrokers :path(ROOTS_DASHBOARD,'/vendor/all-brokers'),
    Newbroker :path(ROOTS_DASHBOARD,'/vendor/all-brokers/add-new-broker'),
    // AllServices :path(ROOTS_DASHBOARD,'/vendor/allservices'),
    AddVentor:path(ROOTS_DASHBOARD,'/vendor/add-vendor'),
  },
adding:{
  root: path(ROOTS_DASHBOARD, '/add'),
  NewCustomer :path(ROOTS_DASHBOARD,'/add/newcustomer'),
  AddeditCustomer:path(ROOTS_DASHBOARD,'/add/addeditcustomer'),
  ViewCustomer:path(ROOTS_DASHBOARD,'/add/viewcustomer'),
  AddnewCoupon:path(ROOTS_DASHBOARD,'/add/addnewcoupon'),
  AddeditCoupon:path(ROOTS_DASHBOARD,'/add/addeditcoupon'),
  MenuButtons:path(ROOTS_DASHBOARD,'/add/menubuttons'),
  ViewCoupon:path(ROOTS_DASHBOARD,'/add/view-coupon'),
  AddcustomerPoint:path(ROOTS_DASHBOARD,'/add/add-customer-point'),
  PointMenu:path(ROOTS_DASHBOARD,'/add/point-menu'),
  AddeditPoint:path(ROOTS_DASHBOARD,'/add/add-edit-point'),
  ViewcPoint:path(ROOTS_DASHBOARD,'/add/view-c-point'),

},
pov:{
  root: path(ROOTS_DASHBOARD, '/pov'),
  CustomerLookup :path(ROOTS_DASHBOARD,'/pov/customerLookup'),
  CheckIn :path(ROOTS_DASHBOARD,'/pov/checkIn'),
  ShopCard :path(ROOTS_DASHBOARD,'/pov/shopCard'),
  PaymentProcess :path(ROOTS_DASHBOARD,'/pov/payment-process'),
  AllOrders :path(ROOTS_DASHBOARD,'/pov/allOrders'),
  orderAhead :path(ROOTS_DASHBOARD,'/pov/order-ahead'),
  DrawersManagements:path(ROOTS_DASHBOARD,'/pov/drawers-managements'),

},
bank:{
  root: path(ROOTS_DASHBOARD, '/bank'),
  DrawersManagements:path(ROOTS_DASHBOARD,'/bank/drawers-managements'),
  drawerDetails:path(ROOTS_DASHBOARD,'/bank/drawer-details')
},

inventory:{
  root: path(ROOTS_DASHBOARD, '/inventory'),
  Rooms:path(ROOTS_DASHBOARD,'/inventory/rooms'),
  AddRoom:path(ROOTS_DASHBOARD,'/inventory/addroom'),
  AddeditRoom:path(ROOTS_DASHBOARD,'/inventory/addeditroom'),
  Inventory:path(ROOTS_DASHBOARD,'/inventory/inventory'),
  AddeditInventory:path(ROOTS_DASHBOARD,'/inventory/addeditinventory'),
  inventoryAudit:path(ROOTS_DASHBOARD,'/inventory/audit-inventory'),
  PricingGroup:path(ROOTS_DASHBOARD,'/inventory/pricing-group'),
  AddeditPrice:path(ROOTS_DASHBOARD,'/inventory/add-edit-price'),
  Strain:path(ROOTS_DASHBOARD,'/inventory/strain'),
  AddeditStrain:path(ROOTS_DASHBOARD,'/inventory/add-edit-strain'),
  Type:path(ROOTS_DASHBOARD,'/inventory/type'),
  AddeditType:path(ROOTS_DASHBOARD,'/inventory/add-edit-type'),
  Brand:path(ROOTS_DASHBOARD,'/inventory/brand'),
  AddeditBrand:path(ROOTS_DASHBOARD,'/inventory/add-edit-brand'),
  TaxCategory:path(ROOTS_DASHBOARD,'/inventory/taxCategory'),
  AddeditTaxCategory:path(ROOTS_DASHBOARD,'/inventory/add-edit-taxCategory'),
  ProductCategory:path(ROOTS_DASHBOARD,'/inventory/product-category'),
},

reportInventory:{
  root: path(ROOTS_DASHBOARD, '/report-inventory'),
  currentInventory:path(ROOTS_DASHBOARD,'/report-inventory/current-inventory'),
  salesCategory:path(ROOTS_DASHBOARD,'/report-inventory/sales-by-category'),
},
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/portfolio-review-is-this-portfolio-too-creative'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  }
};

export const PATH_DOCS = {
  root: ROOTS_DOCS,
  introduction: path(ROOTS_DOCS, '/introduction'),
  quickstart: path(ROOTS_DOCS, '/quick-start'),
  package: path(ROOTS_DOCS, '/package'),

  // Theme UI
  color: path(ROOTS_DOCS, '/color'),
  typography: path(ROOTS_DOCS, '/typography'),
  icon: path(ROOTS_DOCS, '/icon'),
  shadows: path(ROOTS_DOCS, '/shadows'),
  components: path(ROOTS_DOCS, '/components'),
  settings: path(ROOTS_DOCS, '/settings'),
  tips: path(ROOTS_DOCS, '/tips'),

  // Development
  routing: path(ROOTS_DOCS, '/routing'),
  environmentVariables: path(ROOTS_DOCS, '/environment-variables'),
  stateManagement: path(ROOTS_DOCS, '/state-management'),
  apiCalls: path(ROOTS_DOCS, '/api-calls'),
  analytics: path(ROOTS_DOCS, '/analytics'),
  authentication: path(ROOTS_DOCS, '/authentication'),
  multiLanguage: path(ROOTS_DOCS, '/multi-language'),
  lazyload: path(ROOTS_DOCS, '/lazyload-image'),
  formHelper: path(ROOTS_DOCS, '/form-helper'),

  // Changelog
  support: path(ROOTS_DOCS, '/support'),
  changelog: path(ROOTS_DOCS, '/changelog')
};
