// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
// import SvgIconStyle from '../../components/SvgIconStyle';
import { AccountBalance, Analytics, BarChartOutlined, BookmarkAdd, CalendarViewMonthOutlined, Category, CommentOutlined, ContactMail, CreditCard, DescriptionOutlined, DirectionsCarFilledOutlined, Inventory, LanguageOutlined, ParkOutlined, PeopleAlt, PeopleOutlined, PlaceOutlined, PointOfSale, Redeem, ShoppingCartOutlined, Store, Widgets, WorkOutlineOutlined } from '@material-ui/icons';

// ----------------------------------------------------------------------

// const getIcon = (name) => (
//   <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
// );

// const ICONS = {
//   blog: getIcon('ic_blog'),
//   cart: getIcon('ic_cart'),
//   chat: getIcon('ic_chat'),
//   mail: getIcon('ic_mail'),
//   user: getIcon('ic_user'),
//   calendar: getIcon('ic_calendar'),
//   ecommerce: getIcon('ic_ecommerce'),
//   analytics: getIcon('ic_analytics'),
//   dashboard: getIcon('ic_dashboard'),
//   kanban: getIcon('ic_kanban')
// };

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     {
  //       title: 'app',
  //       path: PATH_DASHBOARD.general.app,
  //       icon: ICONS.dashboard
  //     },
  //     { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //     { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics }
  //   ]
  // },
  {

    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: <Store />,
      },
      {
        title: 'Customers Management',
        path: PATH_DASHBOARD.user.root,
        icon: <ContactMail />,
        children: [
          { title: 'Customers', path: PATH_DASHBOARD.user.allcustomers },
          // {
          //   title: 'Coupons',
          //   path: PATH_DASHBOARD.user.coupon,
          //   children: [
          //     { title: 'Coupons', path: PATH_DASHBOARD.user.cards },
          //     { title: 'Buy One Get One Offers', path: PATH_DASHBOARD.user.coupon },
          //   ]
          // },
          { title: 'Coupons', path: PATH_DASHBOARD.user.coupon },
          { title: 'Customer Points', path: PATH_DASHBOARD.user.CustomerPoint },
        ]
      },

      { title: 'Wholesale Order', path: PATH_DASHBOARD.vendor.root, icon: <ShoppingCartOutlined />,
      children:[
        { title: 'All Vendors', path: PATH_DASHBOARD.vendor.AllVentors },
        { title: 'All Brokers', path: PATH_DASHBOARD.vendor.AllBrokers },
        { title: 'All Vendor Types', path: PATH_DASHBOARD.vendor.AllvenTypes },
        { title: 'Receive Inventory Orders', path: PATH_DASHBOARD.vendor.ReceiverInven },
        // { title: 'Lab Testing Calender', path:PATH_DASHBOARD.calendar },
        
        
      ] },
      // { title: 'Accounts Managements', path: PATH_DASHBOARD.general.analytics, icon: <CreditCard />, },
      { title: 'Point Of Sale', path: PATH_DASHBOARD.pov.root, 
      // icon:<Redeem /> 
      icon:<PointOfSale /> ,
      children:[
        { title: 'Customer Lookup', path: PATH_DASHBOARD.pov.CustomerLookup },
        { title: 'All Orders', path: PATH_DASHBOARD.pov.AllOrders },
        { title: 'Order Que', path: PATH_DASHBOARD.pov.orderAhead },
        { title: 'Drawers Managements', path:PATH_DASHBOARD.pov.DrawersManagements},
      ]
      },
      // { title: 'Bank Management', icon: <AccountBalance />,
      //   children:[
      //     { title: 'Drawers Managements', path:PATH_DASHBOARD.bank.DrawersManagements},
      //   ]
      //  },
      // { title: 'Staff Managements', path: PATH_DASHBOARD.general.analytics, icon: <PeopleAlt />, },
      { title: 'Inventory', path: PATH_DASHBOARD.inventory.root, icon: <Widgets />,
    children:[
      { title: 'Rooms', path: PATH_DASHBOARD.inventory.Rooms},
      { title: 'Inventory', path: PATH_DASHBOARD.inventory.Inventory},
      { title: 'Inventory Audit', path: PATH_DASHBOARD.inventory.inventoryAudit},
      { title: 'Strains', path: PATH_DASHBOARD.inventory.Strain},
      { title: 'Type', path: PATH_DASHBOARD.inventory.Type},
      { title: 'Product Category', path: PATH_DASHBOARD.inventory.ProductCategory},
      { title: 'Brand', path: PATH_DASHBOARD.inventory.Brand},
      { title: 'Tax Category', path: PATH_DASHBOARD.inventory.TaxCategory},
      { title: 'Pricing Group', path: PATH_DASHBOARD.inventory.PricingGroup},
    ]
    },
    {
      title: 'Reports',
      icon: <Analytics />,
      path: PATH_DASHBOARD.reportInventory.root,
      children: [
          { 
            title: 'Inventory Report',
            children:[
              { title: 'Current Inventory', path: PATH_DASHBOARD.reportInventory.currentInventory},
            ]
          },
          {
            title: 'Sales Report',
            children:[
              { title: 'Sales By Category', path: PATH_DASHBOARD.reportInventory.salesCategory},
            ]
          },
      ]
    },
      // { title: 'Warehouse', path: PATH_DASHBOARD.general.analytics, icon: <DescriptionOutlined /> },
      // { title: 'Transportation', path: PATH_DASHBOARD.general.analytics, icon: <DirectionsCarFilledOutlined />, },
      // { title: 'My Calendar', path: PATH_DASHBOARD.calendar, icon: <CalendarViewMonthOutlined />, },
      // { title: 'Reports', path: PATH_DASHBOARD.general.analytics, icon: <BarChartOutlined />, },
      // { title: 'Leaf Management', path: PATH_DASHBOARD.general.analytics, icon: <ParkOutlined />, },
      // { title: 'Metric Locations', path: PATH_DASHBOARD.general.analytics, icon: <PlaceOutlined />, },
      // { title: 'Visitors', path: PATH_DASHBOARD.general.analytics, icon: <PeopleOutlined />, },
      // { title: 'Complaints and Comments', path: PATH_DASHBOARD.general.analytics, icon: <CommentOutlined /> },
      // { title: 'Website', path: PATH_DASHBOARD.general.analytics, icon: <LanguageOutlined /> },
      // { title: 'My Jobs', path: PATH_DASHBOARD.general.analytics, icon: <WorkOutlineOutlined />, },
    ]
  },


  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: '',
  //   items: [
  //     // MANAGEMENT : USER
  //     // {
  //     //   title: 'user',
  //     //   path: PATH_DASHBOARD.user.root,
  //     //   icon: ICONS.user,
  //     //   children: [
  //     //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //     //     { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //     //     { title: 'list', path: PATH_DASHBOARD.user.list },
  //     //     { title: 'create', path: PATH_DASHBOARD.user.newUser },
  //     //     { title: 'edit', path: PATH_DASHBOARD.user.editById },
  //     //     { title: 'account', path: PATH_DASHBOARD.user.account }
  //     //   ]
  //     // },

  //     // MANAGEMENT : E-COMMERCE
  //     // {
  //     //   title: 'e-commerce',
  //     //   path: PATH_DASHBOARD.eCommerce.root,
  //     //   icon: ICONS.cart,
  //     //   children: [
  //     //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //     //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
  //     //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //     //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
  //     //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
  //     //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //     //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
  //     //   ]
  //     // },

  //   //   // MANAGEMENT : BLOG
  //   //   {
  //   //     title: 'blog',
  //   //     path: PATH_DASHBOARD.blog.root,
  //   //     icon: ICONS.blog,
  //   //     children: [
  //   //       { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //   //       { title: 'post', path: PATH_DASHBOARD.blog.postById },
  //   //       { title: 'new post', path: PATH_DASHBOARD.blog.newPost }
  //   //     ]
  //   //   }
  //   ]
  // },

  // // APP
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     { title: 'mail', path: PATH_DASHBOARD.mail.root, icon: ICONS.mail },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban }
  //   ]
  // }
];

export default sidebarConfig;
