import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Box,
    Paper,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { getUserList, deleteUser } from 'src/redux/slices/user';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
// components
import Page from 'src/components/Page';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { UserListHead, UserListToolbar, UserMoreMenu } from 'src//components/_dashboard/user/list';
import { status } from 'nprogress';
import BrandMenu from './BrandMenu';
import { MIconButton } from 'src/components/@material-extend';
import AddeditBrand from './AddeditBrand';
import AddBrand from './AddBrand';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/DefaultValues';
import DeleteConfirmationPopup from 'src/pages/dashboard/DeleteConfirmationPopup';
import useAuth from 'src/hooks/useAuth';
import LoadingScreen from 'src/components/LoadingScreen';
import { useSnackbar } from 'notistack';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    // { id: 'id', label: 'Id', alignLeft: true },
    { id: 'name', label: 'Name', alignLeft: true },
    { id: 'status', label: 'Status', alignLeft: true },
    { id: 'actions', label: 'Actions', }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.label.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

 const  DRAWER_WIDTH=500;

export default function Brand() {
    const theme = useTheme();
    const {user} = useAuth();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('id');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isEdit,setEdit]=useState(false);
    const [editData,setEditData]=useState(null);
    const [brandsList, setBrandsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [isDeletePopUpOpen, setDeletePopUpOpen] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);
    const [deleteItemName, setDeleteItemName] = useState(null);

    useEffect(() => {
        dispatch(getUserList());
    }, [dispatch]);

    const fetchData = async() => {
        setIsLoading(true)
        try{
          const response = await axios.post(`${REST_API_END_POINT}inventory/get-all-brands`,{facility_id:user?.facility_id});
          if(response.data.status === 1){
            setBrandsList(response.data.brands)
            setSelected([])
          }else{
            setBrandsList([])
            setSelected([])
          }
        }catch(error){
          console.log('Error fetching data : ',error);
          setBrandsList([])
          setSelected([])
        }finally{
          setIsLoading(false)
        }
    }
    
    useEffect(()=>{
        fetchData();
    },[refresh])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = brandsList.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [open]);
    const handleToggle = () => {
        setOpen((prev) => !prev);
    };

    const handleClose = () => {
        setOpen(false);
        setRefresh(!refresh);
    };

    const handleDeleteBrand = (id,name) => {
        setDeleteItem(id);
        setDeleteItemName(name);
        setDeletePopUpOpen(true);
    };
    
    const handleDeleteBrands = () => {
        setDeleteItem('multiple');
        setDeletePopUpOpen(true);
    };
    
    const confirmDelete = async () => {
        try{
            setDeletePopUpOpen(false);
            try {
                let ids = [];
                if (deleteItem === 'multiple') {
                    ids = selected
                }else{
                    ids = [deleteItem]
                }
                const facility_id = user?.facility_id;
                const response = await axios.post(`${REST_API_END_POINT}inventory/delete-brand`, { ids, facility_id, userId: user?.id });
                if (response?.data?.status === 1) {
                    if (deleteItem === 'multiple') {
                        enqueueSnackbar(`${selected?.length} brand(s) deleted successfully.`, { variant: 'success' });
                    }else{
                        enqueueSnackbar( `Brand "${deleteItemName}" is deleted successfully.`, { variant: 'success' });
                    }
                    setRefresh(!refresh);
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: 'error' });
                }
            } catch (error) {
              console.log('Error in delete brand', error);
              enqueueSnackbar('Error deleting brand', { variant: 'error' });
            }
        }catch(error){
          enqueueSnackbar('Server Error', { variant: 'error' });
          console.log('Error in confirmDelete',error);
        }
    };
    
    const handleEdit = async (data) => {
        setEdit(true)
        setEditData(data)
    }

    const handleStatusChange = async (id,name,status) => {
        try{
            let newStatus = '';
            let message = '';
            if(status == 1){
                newStatus = 0
                message = `Brand "${name}" deactivated successfully`
            }else{
                newStatus = 1
                message = `Brand "${name}" activated successfully`
            }
            let values = {};
            values['status'] = newStatus;
            values['name'] = name;
            const response = await axios.post(`${REST_API_END_POINT}inventory/edit-brand`,
                {values:values, id:id, facility_id:user?.facility_id, userId:user?.id})
            if(response?.data?.status === 1){
                enqueueSnackbar(message, { variant: 'success' });
                setRefresh(!refresh)
            }else{
                enqueueSnackbar(response?.data?.message, { variant: 'error' });
            }
        }catch(error){
            console.log('Error in status change', error);
            enqueueSnackbar('Server Error', { variant: 'error' });
        }
    }

    const filteredUsers = applySortFilter(brandsList, getComparator(order, orderBy), filterName);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredUsers?.length) : 0;
    const isUserNotFound = filteredUsers?.length === 0;

    return (
        isLoading ? 
            <LoadingScreen />
        :
        <Page title="Batman Distribution">
            <Container>
                <HeaderBreadcrumbs
                    heading="Brand"
                    action={
                        <Button
                            variant="contained"
                            onClick={()=>{ setEdit(false); setEditData(null); handleToggle();}}
                            startIcon={<Icon icon={plusFill} />}
                        >
                            Add New
                        </Button>
                    }
                />

                <Card>
                    <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} item={'brand'} onDelete={handleDeleteBrands}/>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 400,whiteSpace:'nowrap' }}>
                            <Table size='small'>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={filteredUsers?.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
                                        const { id, label, status } = row;
                                        const isItemSelected = selected.indexOf(id) !== -1;
                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                                                </TableCell>
                                                {/* <TableCell align="left">{id}</TableCell> */}
                                                <TableCell align="left" component="th" scope="row" padding="none">
                                                    {label}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Label
                                                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                                        color={(status === 0 && 'error') || 'success'}
                                                    >
                                                        {sentenceCase(status === 0 ? 'Inactive' : 'Active')}
                                                    </Label>
                                                </TableCell>


                                                <TableCell align="center">
                                                    <BrandMenu onDelete={() => handleDeleteBrand(id,label)} onhandleToggls={ handleToggle} status={status} onStatusChange={()=>handleStatusChange(id,label,status)} onEdit={()=>handleEdit(row)}/>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredUsers?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
                <DeleteConfirmationPopup
                    open={isDeletePopUpOpen}
                    onClose={()=>setDeletePopUpOpen(false)}
                    onConfirm={confirmDelete}
                    itemContent = 'Are you sure you want to delete?'
                    itemTitle = "Confirm Deletion"
                />
                <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                    <DialogTitle>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="h4">{!isEdit ? 'Add Brand' : 'Edit Brand'}</Typography>
                        <MIconButton onClick={handleClose}>
                            <Icon icon={closeFill} width={20} height={20} />
                        </MIconButton>
                        </Stack>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Stack spacing={4} sx={{ pt: 3, px: 3, pb: 3 }}>
                        <AddBrand onHandleCloses={handleClose} isEdit={isEdit} currentUser={editData} />
                        </Stack>
                    </DialogContent>
                </Dialog>
            </Container>
        </Page>
    );
}
