import { Link as RouterLink } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography, Button } from '@material-ui/core';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import AuthFirebaseSocials from '../../components/authentication/AuthFirebaseSocial';
import image from '../../logo/bg-image.jpg'
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  overflow:'hidden',
  [theme.breakpoints.up('md')]: {
    display: 'flex',

  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 410,
  margin: 'auto',
  display: 'flex',
  minHeight: 400,
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(0, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method, login } = useAuth();

  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootStyle title="Batman Distribution - Sign in" sx={{backgroundImage:`url(${image})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',height:'100%' ,py:14,overflow:'hidden',}}>
    {/* <RootStyle title="Batman Distribution - Sign in" sx={{backgroundImage:'url(https://giffiles.alphacoders.com/220/220245.gif)',backgroundRepeat:'no-repeat',backgroundSize:'cover',height:'100%' ,py:14}}> */}
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
          Get started
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden> */}

      <Container maxWidth="sm" sx={{overflow:'hidden',}}>
       
        <Card  sx={{
          backgroundColor:'rgba(255, 255, 255, 0.4)',
          p:2,
          backdropFilter: 'blur(8px)',WebkitBackdropFilter: 'blur(8px)',
          overflow:'hidden',
          }}>
          <ContentStyle>
            <Stack  alignItems="center" >

            {/* <img src={image} height='80' width='80'/> */}
                {/* <svg viewBox="0 0 288.15 112.94" xmlns="http://www.w3.org/2000/svg" width="80" height="64"><path d="M281.28 87.61c-4.1 0-7-3-7-7.28s2.87-7.28 7-7.28 6.87 3 6.87 7.28-2.77 7.28-6.87 7.28zM154.64 24.56l-.94 8.59c-3.24-6.47-10.31-9.82-18.71-9.82-16.07 0-28.81 12.73-28.81 32.14s12.36 32.14 28.19 32.14c9.77 0 16-4.58 19.16-10.11l1.11 8.88H166V24.56zM136 76.12c-10.5 0-18-8.64-18-20.9s7.34-20.9 18-20.9 17.58 8.51 17.58 20.9-7.13 20.9-17.58 20.9zM212.15 0v72.54s0 13.89 15.53 13.89h6.6v-11h-5.65c-2.59 0-4.14-2-4.14-7V0z" class="logo_svg__cls-1" fill="#000"></path><path d="m288.15 24.56-23.27 68.8c-3.58 11-9.75 19.58-22.89 19.58a21.31 21.31 0 0 1-14.85-6l5.2-9a14.81 14.81 0 0 0 9.16 3.59c5.19 0 9.15-4.46 11-9.9l1.73-5.2h-3.9l-21.65-61.87h13.73l16.1 49.65h.19l16.21-49.65zM44 75.43v11H0V0h12.36v75.43zm58-16.84H55.63c1 11 6.93 18.31 18.68 18.31 5.82 0 11.88-3 14.48-9H101c-3.09 13-15.19 19.79-26.84 19.79-19 0-30.81-12.86-30.81-32.41 0-18.81 12.87-31.92 29.82-31.92 15.42 0 28.75 9.49 28.83 31.28v3.95zm-12-9.53c-.22-8.16-6.28-15.46-16.43-15.46-9.16 0-15.59 6.06-17.44 15.46zM198.67 11H208V0h-13.5c-9.07 0-15 6.89-15 15.49v9.07h-9.37V35h9.37v51.43h12.4L192 35h15.4V24.56H192v-6.69c-.1-5.25 2.36-6.87 6.67-6.87z" class="logo_svg__cls-1" fill="#000"></path></svg> */}
             
            </Stack>
            {method === 'firebase' && <AuthFirebaseSocials />}
            {/* <Alert severity="info" sx={{ mb: 3 }}>
              Use email : <strong>admin@batman.com</strong> / password :<strong>&nbsp;demo1234</strong>
            </Alert> */}
            {method !== 'auth0' ? (
              <LoginForm />
            ) : (
              <Button  size="large" type="submit" variant="contained" onClick={handleLoginAuth0}>
                LOGIN
              </Button>
            )}
            {/* <MHidden width="smUp">
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?&nbsp;
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  Get started
                </Link>
              </Typography>
            </MHidden> */}
          </ContentStyle>
        </Card>
      </Container>
    </RootStyle>
  );
}
