import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@material-ui/lab';
import {
    Box,
    Card,
    Grid,
    Stack,
    TextField,
    Button,
    Autocomplete,
    FormControlLabel,
    Switch,
    Typography
} from '@material-ui/core';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useState } from 'react';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';

// ----------------------------------------------------------------------

AddVentor.propTypes = {
    isEdit: PropTypes.bool,
    currentUser: PropTypes.object
};

const TYPE = [
    { label: 'High Prior Type', code: 'per' },
    { label: 'fgf', code: 'dollar' },
    { label: 'fgf', code: 'do' },
    { label: 'type ventor', code: 'type' }
];

const LIMIT = [
    { label: 'Per Day', code: 'day' },
    { label: 'Per Week', code: 'week' },
    { label: 'Per Month', code: 'month' },
    { label: 'Per Year', code: 'year' }
];

export default function AddVentor({ isEdit, currentUser }) {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const NewUserSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        address: Yup.string().required('Address is required'),
        zipcode: Yup.string().required('Zipcode is required'),
        city: Yup.string().required('City is required'),
        ventortype: Yup.object().required('Select a Type').nullable(),
        limit: Yup.object().required('Select a Limit').nullable(),
        phoneNumber: Yup.string().required('Phone number is required'),
        email: Yup.string().required('Email is required'),
        trade: Yup.string().required('Trade is required'),
        state: Yup.string().required('State is required')
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: currentUser?.name || '',
            ventortype: currentUser?.ventortype || null,
            limit: currentUser?.limit || null,
            address: currentUser?.address || '',
            state: currentUser?.state || '',
            zipcode: currentUser?.zipcode || '',
            trade: currentUser?.trade || '',
            phoneNumber: currentUser?.phoneNumber || '',
            email: currentUser?.email || '',
            city: currentUser?.city || ''
        },
        validationSchema: NewUserSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                resetForm();
                setSubmitting(false);
                enqueueSnackbar(!isEdit ? 'Added successfully' : 'Update success', { variant: 'success' });
                navigate(PATH_DASHBOARD.user.allcustomers);
            } catch (error) {
                setSubmitting(false);
            }
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    const [sameAs, setSameAs] = useState('');
    const [toggleButton, setToggleButton] = useState(false);

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Card sx={{ p: 5 }}>
                            <HeaderBreadcrumbs
                                heading={'Add New Ventor'} />
                            <Stack spacing={3}>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Vendor Name"
                                        {...getFieldProps('name')}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            getFieldProps('name').onChange(e);
                                            setSameAs(value);
                                        }}
                                        error={Boolean(touched.name && errors.name)}
                                        helperText={touched.name && errors.name}
                                    />
                                </Stack>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            {...getFieldProps('status')}
                                            checked={toggleButton}
                                            onChange={(e) => {
                                                setToggleButton(e.target.checked);
                                                setFieldValue('trade', e.target.checked ? sameAs : '');
                                            }}
                                        />
                                    }
                                    label="Same as Vendor Name"
                                />
                                <TextField
                                    fullWidth
                                    label="Trade Name"
                                    value={toggleButton ? sameAs : ''}
                                    {...getFieldProps('trade')}
                                    error={Boolean(touched.trade && errors.trade)}
                                    helperText={touched.trade && errors.trade}
                                />

                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Vendor Address"
                                        {...getFieldProps('address')}
                                        error={Boolean(touched.address && errors.address)}
                                        helperText={touched.address && errors.address}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Vendor City"
                                        {...getFieldProps('city')}
                                        error={Boolean(touched.city && errors.city)}
                                        helperText={touched.city && errors.city}
                                    />
                                </Stack>

                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="State"
                                        {...getFieldProps('state')}
                                        error={Boolean(touched.state && errors.state)}
                                        helperText={touched.state && errors.state}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Zip/Code"
                                        {...getFieldProps('zipcode')}
                                        error={Boolean(touched.zipcode && errors.zipcode)}
                                        helperText={touched.zipcode && errors.zipcode}
                                    />
                                </Stack>

                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="Phone Number"
                                        {...getFieldProps('phoneNumber')}
                                        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                        helperText={touched.phoneNumber && errors.phoneNumber}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Email Address"
                                        {...getFieldProps('email')}
                                        error={Boolean(touched.email && errors.email)}
                                        helperText={touched.email && errors.email}
                                    />
                                </Stack>

                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <Autocomplete
                                        fullWidth
                                        options={TYPE}
                                        getOptionLabel={(option) => option.label}
                                        value={formik.values.ventortype || null}
                                        onChange={(event, newValue) => {
                                            setFieldValue('ventortype', newValue || '');
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Select type"
                                                error={Boolean(touched.ventortype && errors.ventortype)}
                                                helperText={touched.ventortype && errors.ventortype}
                                            />
                                        )}
                                    />

                                    <Autocomplete
                                        fullWidth
                                        options={LIMIT}
                                        getOptionLabel={(option) => option.label}
                                        value={formik.values.limit || null}
                                        onChange={(event, newValue) => {
                                            setFieldValue('limit', newValue || '');
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Select limit"
                                                error={Boolean(touched.limit && errors.limit)}
                                                helperText={touched.limit && errors.limit}
                                            />
                                        )}
                                    />
                                </Stack>

                                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="outlined" sx={{ mr: 1 }}>
                                        <Link to={PATH_DASHBOARD.vendor.AllVentors} style={{ textDecoration: 'none' }}>Cancel</Link>
                                    </Button>
                                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                        {!isEdit ? 'Add' : 'Save Changes'}
                                    </LoadingButton>
                                </Box>
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    );
}
